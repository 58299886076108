

.businessDirectoryTitleContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.businessDirectoryTitleText {
  font-size: 30px;
  color: #fff;
}
.alphabetListContainer {
  position: absolute;
  margin: 0;
  height: calc(100vh - 160px - 100px);
  width: 100vw;
  top: 160px;
  left: 0;
}



.gpAlphaListNav {
  position: fixed;
  top: 60px;
  color: #000;
  width: 40px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gpAlphaListItemPointer {
  padding-top:10px;
  padding-bottom:50px;
}


@media only screen and (max-width: 800px) {
  .gpAlphaListNav {
    right: 20px;
  }
}
@media only screen and (min-width: 801px) {
  .gpAlphaListNav {
    right: 20px;
  }
}