.ShcTextStyles_paragraphText {
  font-size: 14px;
  color: #444;
}
.ShcTextStyles_highlight{
  color: #2980b9;
}

.ShcTextStyles_smallNoteText {
  font-size: 12px;
  color: #444;
}
.ShcTextStyles_tinyNoteText {
  font-size: 11px;
  color: #444;
}