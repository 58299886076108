



.alphabetListParentContainer {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
}
.alphabetListContentContainer {
  /*width: 100%;
  height: 500px;
  overflow: auto;
  padding-right: 12px;*/

  position: absolute;
  margin: 0;
  height: calc(100vh - 160px - 100px);
  width: 100vw;
  top: 20px;
  left: 0;
  
  padding-left: 50px;
  overflow: auto;
}


.alphabetListNavigation {
  position: absolute;
  top: 12px;
  right: 50px;
  color: #000;
}
.alphabetListNavigationItem {
  font-size: 18px;
  vertical-align: top;
  cursor: pointer;
  color: #000;
}
